@import "../../local_modules/normalize.css/normalize";


*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    box-sizing: border-box;
}

body {
    margin: 0;
  background: #0D1016;
}

img {
    max-width: 100%;
}

a {
    text-decoration: none;
}

body {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  letter-spacing: -0.02em;
  overflow-x: hidden;
}

.container {
  max-width: 100%;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}

.mobile {
  display: none;
}
.ui-icon-svg.m {
  height: 24px;
  width: 24px;
  color: #80858f;
  transition: all 0.2s;
}
.site-content {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
  overflow: hidden;
}
.main-content {
  max-width: 100%;
  overflow: hidden;
  margin-left: auto;
}

.logo-small {
  display: none;
  width: 40px;
  height: 40px;
}
.left-menu .section-categories__item span.hidden {
  display: none;
}
.left-menu.close {

}
.left-menu.close {
  width: 70px;
  .logo-small {
    display: block;
  }
  .main-logo {
    display: none;
  }
}
.main-content.full {
  width: calc(100% - 70px);
}
.left-menu.close .ui-sidebar-toggle__content[_ngcontent-pu-site-c59] {
  transform: rotate(0);
}

.left-menu {
  width: 210px;
  padding: 16px 16px 12px;
  position: fixed;
  height: 100vh;
  transition: all 0.2s;
  z-index: 99;
  .ui-sidebar-toggle {
    display: block;
    position: absolute;
    width: 1px;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 1;
    transform: translate(0px);
    transition: .2s ease-in-out;
    z-index: 1;
    background: #0d1016;
  }
  &:hover {
    &:before {
      opacity: 1;
      transition: .0s ease-in-out;
      transition-delay: .0s;
    }
    .ui-sidebar-toggle__wrapper {
      opacity: 1;
      right: -21px;
    }
    .ui-sidebar-toggle__wrapper:before, .ui-sidebar-toggle__btn:after, .ui-sidebar-toggle__btn:before, .ui-sidebar-toggle__wrapper:after {
      opacity: 1;
    }
  }
  .ui-sidebar-toggle__wrapper {
    position: absolute;
    top: calc(41.59% - 20px);
    height: 40px;
    width: 40px;
    right: -1px;
    z-index: 20;
    opacity: 0;
    transition: all 0.1s;
  }
  .ui-sidebar-toggle__wrapper:before {
    content: '';
    top: -3px;
    bottom: -3px;
    left: calc(51% - 2px);
    background: #0d1016;
    width: 2px;
    position: absolute;
    opacity: 0;
    transition: .2s ease-in-out;
    transition-delay: .1s;
  }
  .ui-sidebar-toggle__btn:after {
    content: "";
    position: absolute;
    transition: .2s ease-in-out;
    transition-delay: .1s;
    top: 0;
    bottom: 0;
    left: calc(50% + 2px);
    right: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid transparent;
    border-left: none;
    border-color: #00cfa6;
    opacity: 0;
  }
  .ui-sidebar-toggle__btn:before {
    content: "";
    position: absolute;
    transition: .2s ease-in-out;
    transition-delay: .1s;
    bottom: -3px;
    left: calc(50% - 1px);
    width: 4px;
    height: 4px;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-top-left-radius: 3px;
    border-color: #00cfa6;
    opacity: 0;
  }
  .ui-icon-svg.m {
    color: #00cfa6;
  }
  .ui-sidebar-toggle__wrapper:after {
    top: -3px;
    left: calc(50% - 1px);
    width: 4px;
    height: 4px;
    border-bottom: 1px solid transparent;
    border-left: 1px solid transparent;
    border-bottom-left-radius: 3px;
    transition: .2s ease-in-out;
    transition-delay: .1s;
    content: '';
    position: absolute;
    border-color: #00cfa6;
    opacity: 0;
  }
  .ui-sidebar-toggle__btn[_ngcontent-pu-site-c59] {
    height: 40px;
    width: 40px;
    background-color: #0d1016;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .ui-sidebar-toggle__content[_ngcontent-pu-site-c59] {
    z-index: 1;
    position: relative;
    color: #00cfa6;
    transform: rotate(180deg);
    transition: .2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: -1px;
    width: 1px;
    height: 100%;
    transition: .2s ease-in-out;
    transition-delay: .2s;
    background: linear-gradient(180deg, rgba(0, 207, 166, 0) 17.94%, #00cfa6 40.59%, rgba(0, 207, 166, 0) 63.66%);
    opacity: 0;
  }
  .section-categories__item {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 5px 0;
    transition: all 0.2s;
    &:hover {
      transform: scale(1.05);
      svg {
        color: #fff;
      }
      img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(343deg) brightness(102%) contrast(101%);
      }
      span {
        color: #fff;
      }
    }
    span {
      margin-left: 10px;
      transition: all 0.2s;
    }
    img {
      max-width: 24px;
      max-height: 24px;
      transition: all 0.2s;
    }
  }
}
.left-menu__logo {
  margin-bottom: 37px;
}
.left-menu__logo img {
  width: 142px;
}

.main-content {
  width: calc(100% - 210px);
}

.swiper-container {
  max-width: 100%;
}

a {
  color: #fff;
  transition: all 0.2s;
}

.header-main {
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__desc {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }
  &__left {
    display: flex;
    align-items: center;
  }
  &__links {
    display: flex;
    align-items: center;
  }
  .header-main__links_item {
    display: flex;
    align-items: center;
    margin-right: 28px;
    &:hover {
      span {
        color: #fff;
      }
    }
    &:hover .ui-icon-svg.m {
      color: #fff;
    }
    svg {
      margin-right: 8px;
    }
    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: #93939B;
      transition: all 0.2s;
    }
  }
  &__right {
    display: flex;
    align-items: center;
    a {
      margin-left: 10px;
    }
  }
  @keyframes button-pulse {
    0% {
      transform: translate(-50%,-50%) scale(.7,.5);
      opacity: 0;
      border-radius: .25rem
    }

    12% {
      opacity: 1
    }

    34% {
      transform: translate(-50%,-50%) scale(1)
    }

    38% {
      opacity: 0
    }

    40% {
      transform: translate(-50%,-50%) scale(1);
      opacity: 0;
      border-radius: .5rem
    }

    to {
      transform: translate(-50%,-50%) scale(1);
      opacity: 0;
      border-radius: .25rem
    }
  }
  .btn {
    position: relative;
    &:after {
      width: calc(100% + 24px);
      height: calc(100% + 24px);
      content: "";
      animation: button-pulse 1.6s ease-out infinite .5s;
      position: absolute;
      border-radius: 0.25rem;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%) scale(.7,.5);
      background: rgba(255, 36, 0, .5);
      z-index: -1;
      transition: transform .5s linear;
    }
  }
}

.btn {
  padding: 8px 24px;
  background: #FF2400;
  border: 1px solid #FF2400;
  font-size: 15px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  border-radius: 5px;
  &:hover {
    background: rgba(255, 36, 0, 0.7);
    border-color: rgba(255, 36, 0, 0.7);
  }
}
.btn-border {
  padding: 8px 24px;
  border: 1px solid #FF2400;
  background: transparent;
  font-size: 15px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  border-radius: 5px;
  &:hover {
    background: rgba(255, 36, 0, 0.7);
    border-color: rgba(255, 36, 0, 0.7);
  }
}

.swiper-container {
  overflow: hidden;
  position: relative;
}
#main-slider {
  border-radius: 5px;
  overflow: hidden;
  .swiper-pagination-bullet {
    margin: 0rem 0.125rem;
    width: 0.75rem;
    height: 2px;
    background: #fff;
    opacity: .4;
    border-radius: 2px;
    transition: all 0.2s;
  }
  .swiper-pagination-bullet-active {
    width: 1.5rem;
    opacity: initial;
  }
  .swiper-slide {
    position: relative;
    height: 300px;
    &__content {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 20px 30px 30px;
      height: 100%;
      &_info {
        margin: auto 0;
      }
      .btn {
        margin-top: auto;
      }
      &_head {
        display: flex;
        align-items: center;
        img {
          max-height: 20px;
        }
        span {
          margin-left: 20px;
          padding-left: 20px;
          border-left: 1px solid #fff;
          font-size: 24px;
          line-height: 24px;
          font-weight: bold;
          font-style: italic;
        }
      }
    }
    &__image {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__title {
      font-size: 36px;
      line-height: 48px;
      font-weight: bold;
      margin-bottom: 10px;
      font-style: italic;
      &_desc {
        font-size: 32px;
        line-height: 32px;
        font-weight: bold;
        margin-bottom: 15px;
        font-style: italic;
      }
      &_light {
        font-size: 24px;
        line-height: 24px;
        font-weight: bold;
        margin-bottom: 5px;
        font-style: italic;
      }
    }
  }
}

h1, h2 {
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 0;
}

.title-bottom {
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 130%;
    height: 1px;
    background: linear-gradient(90deg,rgba(0,0,0,0) 0%,#00cfa6 50%,rgba(0,0,0,0) 100%);
    border-radius: 100px;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
    height: 3px;
    background: #00CFA6;
    border-radius: 1px;
  }
}
.section-bonuses {
  .title-bottom {
    &:before {
      background: linear-gradient(90deg,rgba(0,0,0,0) 0%,#ff2400 50%,rgba(0,0,0,0) 100%);
    }
    &:after {
      background: #ff2400;
    }
  }
}

.section-head-desc {
  padding: 60px 0;
  &__head {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0;
  }
}

.section-categories {
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .swiper-container {
      width: calc(100% - 185px);
    }
  }
  .btn-border {
    padding: 10px 24px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      color: #80858f;
      font-size: 14px;
    }
  }
  .swiper-slide {
    width: auto;
    &:hover {
      transform: scale(1.05);
      svg {
        color: #fff;
      }
      img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(343deg) brightness(102%) contrast(101%);
      }
      span {
        color: #fff;
      }
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
  .swiper-button-prev {
    left: 0;
    transform: rotate(180deg);
  }
  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev, .swiper-button-next {
    background: linear-gradient(270deg, #0d1016 0%, rgba(13, 16, 22, 0) 86.76%);
    width: 60px;
    height: 100%;
    &:after {
      display: none;
    }
  }
  .swiper-button-disabled {
    background: none;
  }
  .swiper-slide.active-category {
    img {
      filter: brightness(0) saturate(100%) invert(28%) sepia(100%) saturate(5905%) hue-rotate(6deg) brightness(104%) contrast(105%);
    }
    svg {
      color: #ff2400;
    }
    span {
      color: #fff;
    }
  }
}

.ui-block-title__glow_green[_ngcontent-pu-site-c14] {
  background: radial-gradient(50% 50% at 50% 50%,rgba(0,207,166,.1) 0%,rgba(0,0,0,0) 100%);
}
.ui-block-title__glow_green[_ngcontent-pu-site-c14]:before {
  background: radial-gradient(50% 50% at 50% 50%,rgba(0,207,166,.1) 0%,rgba(0,207,166,.1) 15.45%,rgba(0,0,0,0) 100%);
}
.ui-block-title__glow[_ngcontent-pu-site-c14]:before {
  content: "";
  position: absolute;
  top: 25px;
  width: 100%;
  height: 5px;
  left: 50%;
  transform: translate(-50%);
}
.ui-block-title__glow[_ngcontent-pu-site-c14]:after {
  content: "";
  position: absolute;
  top: 0.5rem;
  width: 144px;
  height: 40px;
  left: 50%;
  transform: translate(-50%);
  background: radial-gradient(50% 50% at 50% 50%,#0d1016 0%,rgba(0,0,0,0) 100%);
}
.ui-block-title__glow[_ngcontent-pu-site-c14]:after {
  width: 207px;
  height: 57px;
  top: 20px;
}
.ui-block-title__glow[_ngcontent-pu-site-c14]:before {
  top: 36px;
  width: 620px;
  height: 7px;
}
.ui-block-title__glow[_ngcontent-pu-site-c14] {
  width: 712px;
  height: 80px;
  top: 9px;
  position: absolute;
}
[_nghost-pu-site-c13] {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.ui-starshine-wrapper[_ngcontent-pu-site-c13] {
  display: block;
  width: 100%;
  height: 100%;
}
.ui-star-small[_ngcontent-pu-site-c13] {
  width: 10px;
  height: 10px;
}
.ui-star-large[_ngcontent-pu-site-c13] {
  width: 50px;
  height: 50px;
}
.ui-star-medium[_ngcontent-pu-site-c13] {
  width: 30px;
  height: 30px;
}
@keyframes _ngcontent-pu-site-c13_glitter {
  0% {
    transform: scale(.3);
    opacity: 0;
  }
  40% {
    transform: scale(1);
    opacity: 1;
  }
  80% {
    transform: scale(.3);
    opacity: 0;
  }
  100% {
    transform: scale(.3);
    opacity: 0;
  }
}
.ui-star[_ngcontent-pu-site-c13] {
  display: block;
  background-repeat: no-repeat;
  background-position: center;

  z-index: 2;
  animation: _ngcontent-pu-site-c13_glitter 5s linear 0s infinite normal;
}
.ui-starshine-green[_nghost-pu-site-c13] .ui-star[_ngcontent-pu-site-c13] {
  background-image: url('../img/green-light.png');
  background-image: image-set(url('../img/green-light.png') 1x,url('../img/green-light@2x.png') 2x);
  background-size: 100% 100%;
}
.ui-starshine-red[_nghost-pu-site-c13] .ui-star[_ngcontent-pu-site-c13] {
  background-image: url('../img/red-light.png');
  background-image: image-set(url('../img/red-light.png') 1x,url('../img/red-light@2x.png') 2x);
  background-size: 100% 100%;
}

.section-bonuses {
  padding: 60px 0;
  &__head {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  &__wrapper {
    margin-top: 40px;
  }
  &__item {
    max-width: 350px;
    background: #13171f;
    border-radius: 4px;
  }
  &__image {
    width: 100%;
    height: 160px;
    position: relative;
    .label {
      font-size: .75rem;
      line-height: 1rem;
      font-weight: 500;
      color: #fff;
      text-transform: uppercase;
      border-radius: 0.125rem;
      padding: 2px 0.25rem;
      text-align: center;
      background: #ff2400;
      position: absolute;
      left: 10px;
      top: 10px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__content {
    padding: 10px;
  }
  &__title {
    font-size: 1.10rem;
    line-height: 1.625rem;
    font-weight: 500;
    color: #fff;
    text-align: center;
    margin-bottom: 10px;
  }
  &__desc {
    font-size: .875rem;
    line-height: 1.125rem;
    font-weight: 400;
    color: #80858f;
    text-align: center;
    margin-bottom: 30px;
    min-height: 72px;
  }
  &__buttons {
    display: flex;
    align-items: center;
    margin-top: auto;
    .btn {
      width: calc(100% - 50px);
      justify-content: center;
      text-align: center;
    }
    .btn-info {
      width: 37px;
      height: 37px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #80858f;
      position: relative;
      margin-left: 10px;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 0.25rem;
        padding: 1px;
        -webkit-mask: linear-gradient(#fff 0 0) content-box,linear-gradient(#fff 0 0);
        mask: linear-gradient(#fff 0 0) content-box,linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        background: #444852;
      }
      &:hover {
        color: #c2c4c9;
        svg {
          color: #fff;
        }
        &:before {
          background: linear-gradient(136.34deg, #00cfa6 0%, #444852 59.09%);
        }
      }
    }
  }
}
.ui-starshine-white {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.ui-starshine-wrapper[_ngcontent-pu-site-c9] {
  display: block;
  width: 100%;
  height: 100%;
}
.ui-starshine-white[_nghost-pu-site-c9] .ui-star[_ngcontent-pu-site-c9] {
  background-image: url('../img/white-light.png');
  background-image: image-set(url('../img/white-light.png') 1x,url('../img/white-light@2x.png') 2x);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}
@keyframes _ngcontent-pu-site-c9_glitter {
  0% {
    transform: scale(.3);
    opacity: 0;
  }
  40% {
    transform: scale(1);
    opacity: 1;
  }
  80% {
    transform: scale(.3);
    opacity: 0;
  }
  100% {
    transform: scale(.3);
    opacity: 0;
  }
}
.ui-star[_ngcontent-pu-site-c9] {
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  z-index: 2;
  opacity: 0;
  animation: _ngcontent-pu-site-c9_glitter 5s linear 0s infinite normal;
}
.ui-star-small[_ngcontent-pu-site-c9] {
  width: 24px;
  height: 24px;
}
.ui-star-large[_ngcontent-pu-site-c9] {
  width: 100px;
  height: 100px;
}
.ui-star-medium[_ngcontent-pu-site-c9] {
  width: 68px;
  height: 68px;
}
.section-text-white {
  padding-bottom: 30px;

  .section-bonuses__head {
    position: relative;
    padding-top: 20px;
  }
  .title-bottom {
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 130%;
      height: 1px;
      background: linear-gradient(90deg,rgba(0,0,0,0) 0%,#ffffff 50%,rgba(0,0,0,0) 100%);
      border-radius: 100px;
    }
    &:after {
      background: #fff;
    }
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;

  }
  ul {
    margin-left: 0;
    padding-left: 20px;
    li {
      margin-bottom: 15px;
    }
  }
}
.section-text-pink {
  padding-bottom: 30px;
  .section-bonuses__head {
    position: relative;
    padding-top: 20px;
  }
  .title-bottom {
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 130%;
      height: 1px;
      background: linear-gradient(90deg,rgba(0,0,0,0) 0%,#f32293 50%,rgba(0,0,0,0) 100%);
      border-radius: 100px;
    }
    &:after {
      background: #f32293;
    }
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;

  }
  ul {
    margin-left: 0;
    padding-left: 20px;
    li {
      margin-bottom: 15px;
    }
  }
}
.section-text-blue {
  padding-bottom: 30px;
  .section-bonuses__head {
    position: relative;
    padding-top: 20px;
  }
  .title-bottom {
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 130%;
      height: 1px;
      background: linear-gradient(90deg,rgba(0,0,0,0) 0%,#00b2ff 50%,rgba(0,0,0,0) 100%);
      border-radius: 100px;
    }
    &:after {
      background: #00b2ff;
    }
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;

  }
  ul {
    margin-left: 0;
    padding-left: 20px;
    li {
      margin-bottom: 15px;
    }
  }
}

.sticky {
  position: fixed;
  z-index: 101;
}
.stop {
  position: relative;
  z-index: 101;
}

.banner {
  max-width: 100%;
  width: 100%;
  margin: 30px 0;
  height: 170px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__content {
    position: absolute;
    left: 100px;
    top: 50%;
    transform: translateY(-50%);
    a {
      padding: 16px 32px;
    }
  }
}
.banner.align-right {
  .banner__content {
    position: absolute;
    left: auto;
    right: 100px;
    top: 50%;
    transform: translateY(-50%);
    a {
      padding: 16px 32px;
    }
  }
}

[_nghost-pu-site-c9] {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.ui-starshine-wrapper[_ngcontent-pu-site-c9] {
  display: block;
  width: 100%;
  height: 100%;
}
.ui-starshine-magenta[_nghost-pu-site-c9] .ui-star[_ngcontent-pu-site-c9] {
  background-image: url('../img/magenta-light.png');
  background-image: image-set(url('../img/magenta-light.png') 1x,url('../img/magenta-light@2x.png') 2x);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}
.ui-starshine-blue[_nghost-pu-site-c9] .ui-star[_ngcontent-pu-site-c9] {
  background-image: url('../img/blue-light.png');
  background-image: image-set(url('../img/blue-light.png') 1x,url('../img/blue-light@2x.png') 2x);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}
.ui-star-small[_ngcontent-pu-site-c9] {
  width: 24px;
  height: 24px;
}
.ui-star-large[_ngcontent-pu-site-c9] {
  width: 100px;
  height: 100px;
}
.ui-star-medium[_ngcontent-pu-site-c9] {
  width: 68px;
  height: 68px;
}
@keyframes _ngcontent-pu-site-c9_glitter {
  0% {
    transform: scale(.3);
    opacity: 0;
  }
  40% {
    transform: scale(1);
    opacity: 1;
  }
  80% {
    transform: scale(.3);
    opacity: 0;
  }
  100% {
    transform: scale(.3);
    opacity: 0;
  }
}
.ui-star[_ngcontent-pu-site-c9] {
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  z-index: 2;
  opacity: 0;
  animation: _ngcontent-pu-site-c9_glitter 5s linear 0s infinite normal;
}

[_nghost-pu-site-c82] {
  position: sticky;
  position: -webkit-sticky;
  bottom: -1px;
  left: 0;
  padding-bottom: 1px;
  align-items: center;
  width: 100%;
  background-color: var(--color-label-winner-bg);
  z-index: 500;
  overflow: hidden;
  margin-bottom: 2rem;
}
.ui-running-line__dropdown[_ngcontent-pu-site-c82] {
  font-size: .75rem;
  line-height: 1rem;
  font-weight: 400;
  padding: 0.5rem 0rem 0.5rem 1.5rem;
  display: flex;
  color: #80858f;
  width: 130px;
  text-align: left;
  text-transform: none;
  margin: 0 auto;
  background: transparent;
  border: none;
  box-shadow: none;
  position: relative;
}
.ui-running-line__dropdown[_ngcontent-pu-site-c82]:after {
  content: "";
  display: block;
  position: absolute;
  width: 1px;
  height: 24px;
  background-color: #80858f;
  opacity: .5;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.ui-running-line_intersected[_nghost-pu-site-c82] {
  background: transparent;
  display: flex;
  align-items: center;
}
.ui-running-line__items[_ngcontent-pu-site-c82] {
  display: flex;
  flex: 1;
  overflow: hidden;
  position: relative;
  max-width: 320px;
  width: 100%;
  margin: 0 auto;
}
.ui-running-line__items[_ngcontent-pu-site-c82] {
  max-width: 100%;
}
.ui-running-line__items-list[_ngcontent-pu-site-c82] {
  display: flex;
}
.ui-running-line__item {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  max-height: 32px;
}
.ui-running-line__item img {
  height: 32px;
  display: block;
  margin-bottom: 0;
}
.ui-running-line-item__game-btn {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}
.ui-running-line__items {
  padding-left: 20px;
}
.ui-running-line__item {
  width: 120px;
}
.ui-running-line-item__name {
  font-size: .75rem;
  line-height: 1rem;
  font-weight: 400;
  display: block;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #c2c4c9;
}
.ui-running-line-item__value {
  font-size: .75em;
  line-height: 1em;
  font-weight: 400;
  color: #fff;
  margin-bottom: 0;
}

.ui-icon-svg {
  width: 15px;
  height: 15px;
}
.ui-button.ui-button_primary.ui-button_s {
  background: #ff2400;
  display: flex;
  align-items: center;
  color: #fff;
  border-radius: 4px;
  border: none;
  box-shadow: none;
  outline: none;
  padding: 5px 10px;
  &:hover {
    cursor: pointer;
  }
  svg {
    color: #fff;
    margin-right: 8px;
  }
}
.ui-running-line__item:hover .ui-running-line-item__game-btn {
  opacity: 1;
  background: #0D1016;

}


.footer-main {
  margin-bottom: 30px;
  &__block {
    max-width: 25%;
  }
  &__title {
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: 500;
    color: #fff;
    margin-bottom: 10px;
  }
  &__buttons {
    .pu-footer-support__buttons {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
  }
  ul {
    margin-left: 0;
    padding-left: 20px;
    li {
      padding-left: 0;
      &::marker {
        color: #80858f;
      }
    }
  }
  ul li {
    a {
      font-size: .75rem;
      line-height: 1rem;
      font-weight: 400;
      position: relative;
      color: #80858f;
      text-decoration: none;
    }
  }
  .container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .ui-button_l.ui-button_tertiary, .ui-button_l.ui-button_tertiary-accent {
    padding: 0.5rem 1.5rem;
  }
  .pu-footer-support__button[_ngcontent-pu-site-c73] {
    font-size: .875rem;
    line-height: .875rem;
    font-weight: 500;
    padding: 0.5rem 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    margin-bottom: 10px;
    width: 100%;
  }
  .pu-footer-support__button-icon[_ngcontent-pu-site-c73] {
    margin-right: 0.5rem;
  }
  .ui-button_l .ui-icon-svg.m {
    width: 24px;
    height: 24px;
    color: #80858f;
  }
  .ui-button_tertiary {
    position: relative;
    color: #80858f;
  }
  .ui-button_tertiary:not(.ui-button_pressed):not(:active):not(:disabled):hover {
    color: var(--color-button-tertiary-txt-hover);
  }
  .ui-button_tertiary:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0.25rem;
    padding: 1px;
    -webkit-mask: linear-gradient(#fff 0 0) content-box,linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box,linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    background: #444852;
  }
  .ui-button_tertiary:not(.ui-button_pressed):not(:active):not(:disabled):hover {
    cursor: pointer;
    svg {
      color: #fff;
    }
  }
  .ui-button_tertiary:not(.ui-button_pressed):not(:active):not(:disabled):hover:before {
    background: linear-gradient(163deg, #00cfa6 0%, #444852 59.09%);
  }
  .telegram-button.pu-footer-support__button[_ngcontent-pu-site-c73] {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.footer-logos__top {
  overflow: hidden;
  position: relative;
  padding-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  &:after {
    content: "";
    position: absolute;
    bottom: 1px;
    display: initial;
    height: 1px;
    background: linear-gradient(90deg, rgba(30, 35, 46, 0) 0%, rgba(128, 133, 143, .252417) 27.6%, rgba(128, 133, 143, .5) 52.62%, rgba(128, 133, 143, .291283) 74.48%, rgba(13, 16, 22, 0) 100%);
    left: -1rem;
    right: -1rem;
  }
  .swiper-button-prev, .swiper-button-next {
    width: 101px;
    height: 100%;
    margin: 0;
    top: 0;
    &:after {
      display: none;
    }
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next {
    right: 0;
  }
  .swiper-slide {
    width: auto;
    &:hover {
      img {
        filter: grayscale(0);
      }
    }
    img {
      width: auto;
      filter: grayscale(100);
    }
  }
  .ui-slider-controls__container[_ngcontent-pu-site-c69] {
    width: 101px;
  }
  .ui-slider-controls_bottom-gap[_nghost-pu-site-c69] .ui-slider-controls__container[_ngcontent-pu-site-c69] {
    height: calc(100% - 2px);
  }
  .ui-slider-controls__container_next[_ngcontent-pu-site-c69] {
    right: 0;
    justify-content: flex-end;
    background: linear-gradient(270deg, #0d1016 0%, rgba(13, 16, 22, 0) 86.76%);
  }
  .ui-slider-controls__container[_ngcontent-pu-site-c69] {
    position: absolute;
    top: 0;
    z-index: 1;
    pointer-events: none;
    display: flex;
    height: 100%;
    padding: 0;
  }
  .pu-footer-logos__swiper-controls[_nghost-pu-site-c69] .ui-slider-controls__btn[_ngcontent-pu-site-c69], .pu-footer-logos__swiper-controls [_nghost-pu-site-c69] .ui-slider-controls__btn[_ngcontent-pu-site-c69] {
    color: #80858f;
  }
  .ui-slider-controls__btn_next[_ngcontent-pu-site-c69] {
    justify-content: flex-end;
    padding-right: 0.25rem;
  }
  .ui-slider-controls__btn[_ngcontent-pu-site-c69] {
    box-sizing: border-box;
    margin: 0;
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    background: none;
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    letter-spacing: 0;
    text-decoration: none;
    pointer-events: visible;
    display: flex;
    align-items: center;
    width: 50%;
    height: 100%;
    background: transparent;
    color: #80858f;
  }
  .pu-footer-logos__swiper-controls[_nghost-pu-site-c69] .ui-slider-controls__btn[_ngcontent-pu-site-c69], .pu-footer-logos__swiper-controls [_nghost-pu-site-c69] .ui-slider-controls__btn[_ngcontent-pu-site-c69] {
    color: #80858f;
  }
  .ui-icon-svg.l {
    height: 32px;
    width: 32px;
  }
  .ui-slider-controls__icon_prev[_ngcontent-pu-site-c69] {
    transform: rotate(180deg);
  }
  .swiper-button-disabled {
    display: none;
  }
  .ui-slider-controls__container_prev[_ngcontent-pu-site-c69] {
    justify-content: flex-start;
    background: linear-gradient(90deg, #0d1016 0%, rgba(13, 16, 22, 0) 86.76%);
  }
}

.footer-logos__bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  img {
    height: 3rem;
    width: auto;
    filter: grayscale(100);
    &:hover {
      filter: grayscale(0);
    }
  }

}

.footer-bottom {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  &__year {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #80858f;
    color: #80858f;
    border-radius: 4px;

  }
  &__content {
    display: flex;
    flex-direction: column;
    margin-left: 25px;
    p {
      margin-bottom: 0;
      color: #80858f;
      font-size: .75rem;
      line-height: .75rem;
      font-weight: 400;
      position: relative;
    }
  }
}
@keyframes _ngcontent-pu-site-c28_spinner-animation {
  0% {
    transform: rotate(0);
  }

  100% {
     transform: rotate(360deg);
   }
}

.ui-spinner_m[_ngcontent-pu-site-c28] {
  width: 4rem;
  height: 4rem;
}
.ui-spinner__letter[_ngcontent-pu-site-c28] {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding: 8%;
  top: 11%;
}
.ui-spinner__circles[_ngcontent-pu-site-c28] {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
}
.ui-spinner__circles[_ngcontent-pu-site-c28] div[_ngcontent-pu-site-c28]:nth-child(1) {
  animation-delay: -.45s;
}
.ui-spinner__circles[_ngcontent-pu-site-c28] div[_ngcontent-pu-site-c28]:nth-child(2) {
  animation-delay: -.3s;
}
.ui-spinner__circles[_ngcontent-pu-site-c28] div[_ngcontent-pu-site-c28]:nth-child(3) {
  animation-delay: -.15s;
}
.ui-spinner__circles[_ngcontent-pu-site-c28] div[_ngcontent-pu-site-c28] {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 6px solid #00cfa6;
  border-radius: 50%;
  animation: _ngcontent-pu-site-c28_spinner-animation 1.2s cubic-bezier(.5,0,.5,1) infinite;
  border-color: #00cfa6 transparent transparent transparent;
}
.ui-spinner[_ngcontent-pu-site-c28] {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  transform: translateZ(0);
}
.section-categories__spinner {
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0);
}
.section-categories__spinner.visibled {
  transform: scale(1);
}
[_nghost-pu-site-c95] {
  display: grid;
  grid-gap: 0.25rem;
  grid-template-columns: 1fr 1fr;
}

[_nghost-pu-site-c94] {
  --gradient-angle: 136deg;
  position: relative;
  display: grid;
  border-radius: 0.25rem;
  transition: transform .3s ease;
  overflow: hidden;
  width: 100%;
}
.ui-game-tile__picture[_ngcontent-pu-site-c94] {
  grid-area: 1/-1;
  aspect-ratio: 1.3008849558/1;
  position: relative;
  width: 100%;
}
.ui-game-tile__image[_ngcontent-pu-site-c94] {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: .2s linear;
}
.ui-game-tile__content[_ngcontent-pu-site-c94] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  z-index: 1;
  border-radius: 0.25rem;
  overflow: hidden;
}
.ui-game-tile__header[_ngcontent-pu-site-c94] {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.ui-game-tile__name[_ngcontent-pu-site-c94] {
  display: block;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: .875rem;
  line-height: 1.125rem;
  margin: 0;
  font-weight: 400;
  color: #c2c4c9;
  width: 100%;
  opacity: 0;
}
.ui-game-tile__button-icon {
  display: flex;
  svg {
    color: #fff!important;
  }
}
.ui-game-tile__buttons[_ngcontent-pu-site-c94] {
  visibility: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}
.ui-button_primary, .ui-button_primary-animation {
  background: #ff2400;
  color: #fff;
  position: relative;
}
.xl\:ui-button_m {
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 500;
  padding: 0.5rem 1.5rem;
  line-height: 24px;
}
.ui-game-tile__button[_ngcontent-pu-site-c94] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.xl\:ui-button_m .ui-icon-svg.m {
  width: 20px;
  height: 20px;
}
.xl\:ui-button_m .ui-icon+span {
  margin-left: 0.5rem;
}
@keyframes button-pulse {
  0% {
    transform: translate(-50%,-50%) scale(.7,.5);
    opacity: 0;
    border-radius: .25rem
  }

  12% {
    opacity: 1
  }

  34% {
    transform: translate(-50%,-50%) scale(1)
  }

  38% {
    opacity: 0
  }

  40% {
    transform: translate(-50%,-50%) scale(1);
    opacity: 0;
    border-radius: .5rem
  }

  to {
    transform: translate(-50%,-50%) scale(1);
    opacity: 0;
    border-radius: .25rem
  }
}
.ui-button_primary-animation:not(.ui-button_pressed):not(.ui-button_prerender):not(:active):not(:disabled):after {
  content: "";
  animation: button-pulse 1.6s ease-out infinite .5s;
  position: absolute;
  border-radius: 0.25rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%) scale(.7,.5);
  background: rgba(255, 36, 0, .5);
  z-index: -1;
  transition: transform .5s linear;
}
.ui-button.ui-button_primary-animation:after {
  width: calc(100% + 24px);
  height: calc(100% + 24px);
}
.ui-button_special-demo {
  background: #00000000;
  color: #ffffff;
  border: 1px solid #ffffff;
  text-transform: uppercase;
  justify-content: center;
}
.xl\:ui-button_m.ui-button_special-demo {
  font-size: .875rem;
  line-height: .875rem;
  font-weight: 500;
  padding: 0.75rem 44px;
}
.ui-game-tile__button[_ngcontent-pu-site-c94] {
  display: flex;
  justify-content: center;
  align-items: center;
}
[_nghost-pu-site-c94]:hover:not(.ui-game-tile_touch-supported), .ui-game-tile_touched[_nghost-pu-site-c94] {
  transform: scale(1.08);
  z-index: 3;
}
[_nghost-pu-site-c94]:hover:not(.ui-game-tile_touch-supported) .ui-game-tile__content[_ngcontent-pu-site-c94], .ui-game-tile_touched[_nghost-pu-site-c94] .ui-game-tile__content[_ngcontent-pu-site-c94] {
  background-color: rgba(13, 16, 22, .7);
}
[_nghost-pu-site-c94]:hover:not(.ui-game-tile_touch-supported) .ui-game-tile__name[_ngcontent-pu-site-c94], [_nghost-pu-site-c94]:hover:not(.ui-game-tile_touch-supported) .ui-game-tile__favorite[_ngcontent-pu-site-c94], .ui-game-tile_touched[_nghost-pu-site-c94] .ui-game-tile__name[_ngcontent-pu-site-c94], .ui-game-tile_touched[_nghost-pu-site-c94] .ui-game-tile__favorite[_ngcontent-pu-site-c94] {
  opacity: 1;
}
[_nghost-pu-site-c94]:hover:not(.ui-game-tile_touch-supported) .ui-game-tile__buttons[_ngcontent-pu-site-c94], .ui-game-tile_touched[_nghost-pu-site-c94] .ui-game-tile__buttons[_ngcontent-pu-site-c94] {
  visibility: initial;
}
.ui-game-tile__bottom[_ngcontent-pu-site-c94] {
  font-size: .75rem;
  line-height: 1rem;
  font-weight: 400;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(13, 16, 22, .65);
  color: #fff;
  text-transform: uppercase;
  padding: 2px;
}
.ui-game-tile__bottom-icon[_ngcontent-pu-site-c94] {
  margin-right: 0.25rem;
}
.ui-icon-svg.\32xs {
  height: 12px;
  width: 12px;
}
.ui-game-tile__labels[_ngcontent-pu-site-c94] {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0.25rem;
  background-image: linear-gradient(128.83deg, #0d1016 0%, rgba(13, 16, 22, 0) 23.69%);
}
.ui-game-tile__label[_ngcontent-pu-site-c94] {
  font-size: .75rem;
  line-height: 1rem;
  font-weight: 400;
  font-weight: 500;
  width: -webkit-max-content;
  width: max-content;
  padding: 2px 0.25rem;
  color: #fff;
  border-radius: 2px;
  text-transform: uppercase;
}
.ui-game-tile__label_new[_ngcontent-pu-site-c94] {
  background-color: rgba(255, 36, 0, .6);
}
.ui-game-tile__image.pu-img-loaded[_ngcontent-pu-site-c94], .ui-game-tile__image_placeholder[_ngcontent-pu-site-c94] {
  opacity: 1;
}

[_nghost-pu-site-c95] {
  margin-top: 30px;
}

[_nghost-pu-site-c94]:before, [_nghost-pu-site-c90]:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0.25rem;
  padding: 1px;
  -webkit-mask: linear-gradient(#fff 0 0) content-box,linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box,linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  display: none;
  z-index: 2;
  width: calc(100% - 1px * 2);
  height: calc(100% - 1px * 2);
  padding: 1px;
  pointer-events: none;
  background: linear-gradient(496deg,#00cfa6 0%, rgba(13, 16, 22, .2) 59.09%);
}
[_nghost-pu-site-c94]:hover:not(.ui-game-tile_touch-supported):before, .ui-game-tile_touched[_nghost-pu-site-c94]:before {
  display: block;
}
[_nghost-pu-site-c90]:hover:not(.ui-game-tile_touch-supported):before, .ui-game-tile_touched[_nghost-pu-site-c90]:before {
  display: block;
}

@media (min-width: 609px) {
  [_nghost-pu-site-c95] {
    grid-template-columns: repeat(3,1fr);
  }
}
@media (min-width: 768px) {
  [_nghost-pu-site-c95] {
    grid-gap: 0.5rem;
  }
}
@media (min-width: 931px) {
  [_nghost-pu-site-c95] {
    grid-template-columns: repeat(4,1fr);
  }
}
@media (min-width: 1233px) {
  [_nghost-pu-site-c95] {
    grid-template-columns: repeat(5,1fr);
  }
}
@media (min-width: 1280px) {
  [_nghost-pu-site-c95] {
    grid-template-columns: repeat(4,1fr);
  }
}
@media (min-width: 1459px) {
  [_nghost-pu-site-c95] {
    grid-template-columns: repeat(5,1fr);
  }
}
.pu-casino-games-list__grid[_ngcontent-pu-site-c93] {
  padding-top: 0.5rem;
  margin-bottom: 1.5rem;
}
[_nghost-pu-site-c91] {
  display: grid;
  grid-gap: 0.25rem;
  grid-template-columns: 1fr 1fr;
}
@media (min-width: 609px) {
  [_nghost-pu-site-c91] {
    grid-template-columns: repeat(3,1fr);
  }
}
@media (min-width: 768px) {
  [_nghost-pu-site-c91] {
    grid-gap: 0.5rem;
  }
}
@media (min-width: 931px) {
  [_nghost-pu-site-c91] {
    grid-template-columns: repeat(4,1fr);
  }
}
@media (min-width: 1233px) {
  [_nghost-pu-site-c91] {
    grid-template-columns: repeat(5,1fr);
  }
}
@media (min-width: 1280px) {
  [_nghost-pu-site-c91] {
    grid-template-columns: repeat(4,1fr);
  }
}
@media (min-width: 1459px) {
  [_nghost-pu-site-c91] {
    grid-template-columns: repeat(5,1fr);
  }
}
.ui-button_primary, .ui-button_primary-animation {
  border-radius: 6px;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  margin-bottom: 5px;
}
.section-categories__wrapper {
  margin-top: 30px;
}
.ui-game-tile__button[_ngcontent-pu-site-c94] {
  border-radius: 6px;
}
[_nghost-pu-site-c90] {
  --gradient-angle: 136deg;
  position: relative;
  display: grid;
  border-radius: 0.25rem;
  transition: transform .3s ease;
  overflow: hidden;
  width: 100%;
}

.ui-game-tile__picture[_ngcontent-pu-site-c90] {
  grid-area: 1/-1;
  aspect-ratio: 1.3008849558/1;
  position: relative;
  width: 100%;
}
.ui-game-tile__image[_ngcontent-pu-site-c90] {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  transition: .2s linear;
}
.ui-game-tile__content[_ngcontent-pu-site-c90] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  z-index: 1;
  border-radius: 0.25rem;
  overflow: hidden;
}
.ui-game-tile__header[_ngcontent-pu-site-c90] {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.ui-game-tile__name[_ngcontent-pu-site-c90] {
  display: block;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: .875rem;
  line-height: 1.125rem;
  font-weight: 400;
  color: #c2c4c9;
  width: 100%;
  opacity: 0;
  margin: 0;
}
.ui-game-tile__buttons[_ngcontent-pu-site-c90] {
  visibility: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}
.ui-game-tile__button[_ngcontent-pu-site-c90] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ui-game-tile__button[_ngcontent-pu-site-c90] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ui-game-tile__bottom[_ngcontent-pu-site-c90] {
  font-size: .75rem;
  line-height: 1rem;
  font-weight: 400;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(13, 16, 22, .65);
  color: #fff;
  text-transform: uppercase;
  padding: 2px;
}
.ui-game-tile__header[_ngcontent-pu-site-c90] {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
[_nghost-pu-site-c90]:hover:not(.ui-game-tile_touch-supported) .ui-game-tile__name[_ngcontent-pu-site-c90], [_nghost-pu-site-c90]:hover:not(.ui-game-tile_touch-supported) .ui-game-tile__favorite[_ngcontent-pu-site-c90], .ui-game-tile_touched[_nghost-pu-site-c90] .ui-game-tile__name[_ngcontent-pu-site-c90], .ui-game-tile_touched[_nghost-pu-site-c90] .ui-game-tile__favorite[_ngcontent-pu-site-c90] {
  opacity: 1;
}
.ui-game-tile__bottom-icon[_ngcontent-pu-site-c90] {
  margin-right: 0.25rem;
}
[_nghost-pu-site-c90]:hover:not(.ui-game-tile_touch-supported), .ui-game-tile_touched[_nghost-pu-site-c90] {
  transform: scale(1.08);
  z-index: 3;
}
[_nghost-pu-site-c90]:hover:not(.ui-game-tile_touch-supported) .ui-game-tile__content[_ngcontent-pu-site-c90], .ui-game-tile_touched[_nghost-pu-site-c90] .ui-game-tile__content[_ngcontent-pu-site-c90] {
  background-color: rgba(13, 16, 22, .7);
}
.ui-game-tile__content[_ngcontent-pu-site-c90] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  z-index: 1;
  border-radius: 0.25rem;
  overflow: hidden;
}
.ui-game-tile__header[_ngcontent-pu-site-c90] {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
[_nghost-pu-site-c90]:hover:not(.ui-game-tile_touch-supported) .ui-game-tile__buttons[_ngcontent-pu-site-c90], .ui-game-tile_touched[_nghost-pu-site-c90] .ui-game-tile__buttons[_ngcontent-pu-site-c90] {
  visibility: initial;
}

@media screen and (max-width: 1000px) {
  .header-main__desc {
    display: none;
  }
  .ui-block-title__glow[_ngcontent-pu-site-c14]:after {
    display: none;
  }
  h1, h2 {
    font-size: 24px;
  }
}
@media screen and (max-width: 650px) {
  .left-menu {
    display: none;
  }
  .main-content {
    width: 100%;
  }
  #main-slider .swiper-slide__title, #main-slider .swiper-slide__title_desc {
    font-size: 22px;
    line-height: 22px;
  }
  #main-slider .swiper-slide__title_light, #main-slider .swiper-slide__title_desc {
    font-size: 20px;
    line-height: 20px;
  }
  #main-slider .swiper-slide {
    height: 210px;
  }
  #main-slider .swiper-slide__content_head span {
    font-size: 14px;
    line-height: 14px;
  }
  .header-main .header-main__links_item {
    display: none;
  }
  .mobile {
    display: flex;
  }
  .btn, .btn-border, .banner.align-right .banner__content a, .banner__content a {
    font-size: 13px;
    padding: 6px 10px;
  }
  h1, h2 {
    font-size: 20px;
    line-height: 20px;
  }
  .section-head-desc p, .section-text-white p, .section-text-pink p, .section-text-blue p, .section-text-white ul li, .section-text-blue ul li, .section-text-pink ul li {
    font-size: 14px;
    line-height: 16px;
  }
  .section-categories .btn-border {
    display: none;
  }
  .section-categories__head .swiper-container {
    width: 100%;
  }
  .footer-main .container {
    flex-direction: column;
  }
  .footer-main__block {
    max-width: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
  }
  .footer-bottom__content {
    width: calc(100% - 80px);
  }
  .footer-logos__bottom img {
    width: 15%;
  }
  .footer-logos__top .swiper-slide {
    width: 15%;
  }
}



